import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear, faCircleQuestion, faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

const SupportPage = () => {
	return (
		<Layout pageTitle="Support">
			
			<p>Brfify utvecklas utifrån riktiga behov med användare i fokus och i alla delar finns hjälptexter.<br/>Skulle du ändå behöva hjälp finns här support-sidor med information och skulle du sakna något är du varmt välkommen att kontakta <a href="mailto:hej@iterik.se">hej@iterik.se</a>.</p>
			
			<div className="buttons">

				<Link to="/support/app">
					<FontAwesomeIcon icon={faMobileScreenButton} />
					<p>App</p>
				</Link>

				<Link to="/support/webbsida">
					<FontAwesomeIcon icon={faGlobe} />
					<p>Webbsida</p>
				</Link>

				<Link to="/support/admin">
					<FontAwesomeIcon icon={faUserGear} />
					<p>Admin</p>
				</Link>

				<a href="https://www.youtube.com/@brfify/playlists" target="_blank">
					<FontAwesomeIcon icon={faYoutube} />
					<p>Videos</p>
				</a>

				<Link to="/support/fragor-svar">
					<FontAwesomeIcon icon={faCircleQuestion} />
					<p>Frågor &amp; svar</p>
				</Link>
			</div>
			
		</Layout>
	)
}

export default SupportPage